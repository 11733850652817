<template>
  <div
    class="video-player"
    @click="updateVideoStatus()"
  >
    <video
      ref="video"
      :controls="!videoNotPlaying"
      :autoplay="autoplay"
      :loop="loop"
      :muted="muted"
      :poster="poster"
      preload="metadata"
      playsinline
    >
      <source :src="src">
    </video>

    <Transition>
      <button
        v-if="videoNotPlaying"
        class="video-player__play"
      >
        <svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M24 13.268C25.3333 14.0378 25.3333 15.9623 24 16.7321L3 28.8564C1.66666 29.6262 -1.38408e-06 28.664 -1.31678e-06 27.1244L-2.56832e-07 2.87564C-1.89534e-07 1.33604 1.66667 0.373792 3 1.14359L24 13.268Z" fill="#2F57E9"/>
          <!-- fill="#D3FF53" -->
        </svg>
      </button>
    </Transition>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      required: true
    },
    autoplay: {
      required: false,
      type: Boolean,
      default: false
    },
    loop: {
      required: false,
      type: Boolean,
      default: false
    },
    muted: {
      required: false,
      type: Boolean,
      default: false
    },
    poster: {
      required: false,
      type: String,
      default: null
    },
    preload: {
      required: false,
      type: String,
      default: 'auto'
    }
  },
  data () {
    return {
      videoNotPlaying: true,
      videoElement: null,
      paused: null
    }
  },
  methods: {
    updateVideoStatus () {
      if (this.videoNotPlaying) {
        this.videoNotPlaying = false

        setTimeout(() => {
          this.$refs.video.play()
        }, 100)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.video-player {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(156, 163, 184, .6);
  box-shadow: none !important;
  border-radius: 15px;

  &.border-radius-none {
    border-radius: 0 !important;
  }

  &.full-width {
    left: -20px;
    width: calc(100% + 40px);
    border-radius: 0;
  }

  video {
    height: 100%;
    width: 100%;
    border-radius: inherit;
  }

  .video-player__play {
    position: absolute;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;

    svg {
      margin-left: 7px;
    }
  }
}

@media screen and (max-width: 768px) {
  .video-player {
    left: -17px;
    width: calc(100% + 36px);
    border-radius: 0;

    &.border-radius-none {
      left: 0;
      width: 100%;
    }

    &.no-full-width {
      position: static;
      width: 100%;
      border-radius: 10px;
    }

    video {
      min-height: 210px;
      object-fit: cover;
    }

    .video-player__play {
      height: 70px;
      width: 70px;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity .5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
