<template>
  <div class="container">
    <div class="container__control">
      <ClosePopUpButton
        class="black"
      />
    </div>

    <div
      v-if="data"
      class="container__content"
    >
      <h3>
        {{ data.name }}
      </h3>

      <div
        v-if="data.has_video"
        class="text"
        v-html="data.text"
      />

      <img
        v-if="!data.has_video && data.has_image"
        class="image"
        :src="data.image_url"
      >

      <VideoPlayer
        v-if="data.has_video"
        :src="data.video"
        :poster="data.image_url"
        class="video full-width"
      />

      <div
        v-if="!data.has_video"
        class="text"
        v-html="data.text"
      />

      <temolate
        v-if="data.has_button_1"
      >
        <BlueButton
          v-if="data.button_1_link.indexOf('https://') === -1"
          :tag="'RouterLink'"
          :to="`/${$i18n.locale}${data.button_1_link}`"
          :text="data.button_1_text"
        />

        <BlueButton
          v-else
          :tag="'a'"
          target="_blank"
          :href="data.button_1_link"
          :text="data.button_1_text"
        />
      </temolate>

      <temolate
        v-if="data.has_button_2"
      >
        <BlueButton
          v-if="data.button_2_link.indexOf('https://') === -1"
          :tag="'RouterLink'"
          class="light"
          :to="`/${$i18n.locale}${data.button_2_link}`"
          :text="data.button_2_text"
        />

        <BlueButton
          v-else
          :tag="'a'"
          class="light"
          target="_blank"
          :href="data.button_2_link"
          :text="data.button_2_text"
        />
      </temolate>
    </div>
  </div>
</template>

<script>
import ClosePopUpButton from '@/ui/buttons/ClosePopUpButton.vue'
import VideoPlayer from '@/ui/VideoPlayer.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    ClosePopUpButton,
    VideoPlayer,
    BlueButton
  },
  data () {
    return {
      data: null
    }
  },
  created () {
    this.data = this.$store.state.popUp.active.data[0]
  },
  watch: {
    '$store.state.popUp.active.data': {
      handler () {
        if (this.$store.state.popUp.active?.data?.length > 0) {
          this.data = null
          setTimeout(() => {
            this.data = this.$store.state.popUp.active.data[0]
          }, 100)
        } else {
          this.$pop.down()
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  background: white;
  border-radius: 15px;
  overflow: hidden;

  .container__control {
    display: flex;
    justify-content: flex-end;
  }

  .container__content {
    padding: 0 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    h3 {
      font-size: 26px;
      line-height: 100%;
      color: #202539;
      font-family: SemiBold;
    }

    .text {
      font-size: 16px;
      line-height: 150%;
      color: #202539;
    }

    .image {
      width: 100%;
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    max-width: 500px;
    padding: 10px;
    background: white;
    border-radius: 15px;
    overflow: hidden;

    .container__content {
      padding: 0 7px 20px;
    }
  }
}
</style>
